import React from "react"
import PropTypes from "prop-types"

import MarkerIcon from "../../../assets/img/common/ic_location_pin.png"

import GoogleMapReact from "google-map-react"

const Marker = () => <img src={MarkerIcon} className="img-fluid marker-icon" />

export const Map = ({ long, lat, zoom, className }) => {
  return (
    <div id="google-maps" className={`${className ? className : ""}`}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
        defaultCenter={{
          lat: lat,
          lng: long,
        }}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        <Marker lat={lat} lng={long} />
      </GoogleMapReact>
    </div>
  )
}

Map.propTypes = {
  long: PropTypes.any,
  lat: PropTypes.any,
  zoom: PropTypes.number,
  className: PropTypes.string,
}

Map.defaultProps = {
  long: -6.292766,
  lat: 106.6524,
  zoom: 16,
  className: "",
}

export const GoogleMapsEmbed = ({
  embedUrl
}) => {
  return(
    <div 
      className={embedUrl !== "" ? "map-w-100 map-h-600" : "d-none" } 
      dangerouslySetInnerHTML={{__html: embedUrl}} 
    ></div>
  )
}

GoogleMapsEmbed.propTypes = {
  embedUrl: PropTypes.string,
}

GoogleMapsEmbed.defaultProps = {
  embedUrl: "",
}
